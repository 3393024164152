<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <div class="header-title">글쓰기</div>
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-history-back">
          <a href="javascript:" @click="goBack" class="util-actions util-actions-history-back">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
      <!-- header-util -->
      <div class="header-column header-util right">
        <div class="util util-download">
          <button @click="clickSave" class="kb-btn-text-silver">저장</button>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-helpdesk-editor">

    <div class="main-header">
      <!-- main-header > header-snb -->
      <div class="header-snb">
        <nav class="snb">
          <ul class="snb-list">
            <li v-for="(item, idx) in inqTyCdDcds" :key="item.cd" class="snb-item" :class="{'is-active' : idx === 0}">
              <button @click="selectClass(item.cd, idx)">{{ item.cdNm }}</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <!-- main-content -->
    <div class="main-content main-component">
      <div class="board-editor-container">
        <div class="editor-body">
          <div class="editor-body-top">
            <input type="text" v-model="inqItem.inqTitle" id="" name="" placeholder="제목 입력">
            <button type="button" class="kb-btn-editor-more">
              <i class="icon-more"></i>
            </button>
          </div>
          <div class="editor-contents" >
            <!-- 에디터영역 -->
            <div class="content-body" style="background-color: var(--kb-white);">
              <div ref="pageEditor"></div>
            </div>
            <div class="content-footer">
              <div class="footer-column footer-column-right">
<!--                <span class="footer-byte">0 / 3000</span>-->
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import {MUT_TOGGLE_CONCENTRATION_MODE} from '@/store/modules/hrdcloud/hrdcloud';
import {useStore} from 'vuex';
import {onBeforeMount, onBeforeUnmount, onMounted, ref, computed} from 'vue';
import ClassicEditor from '@/assets/js/editor/custom-ckeditor';
import {editorConfig} from '@/assets/js/editor/editor-config';
import {useAlert} from "@/assets/js/modules/common/alert";
import {ACT_INSERT_QA} from "@/store/modules/common/common-index";
import {
  convertToStorageBytes,
  getItem,
  getItems,
  isSuccess,
  isSuccessFailAlert,
  isUploadSuccess,
  lengthCheck
} from "@/assets/js/util";
import {useRoute} from "vue-router";
import {insertFileAtch} from "@/assets/js/modules/hrd/hrd-common";
import {
  ACT_DELETE_HELP_QNA,
  ACT_GET_HELP_FILE_ATCH_LIST,
  ACT_GET_HELP_QNA,
  ACT_UPDATE_HELP_QNA
} from "@/store/modules/help/help";
import {serverUploadError} from '@/assets/js/modules/error/error-message';
import {goBack} from "@/assets/js/modules/common/common";
export default {
  name: 'HelpQnaWrite',
  setup() {
    const store = useStore();
    const route = useRoute();
    const inqItem = ref({
      comInqSn: route.query.comInqSn > 0 ? route.query.comInqSn : 0,
      // tblNm: '',
      // tblSn: 0,
      inqTyCdDcd: '',
      inqTyNm: '카테고리 선택',
      inqTitle: '',
      inqCn: '',
      readYn: '',
      ansYn: '',
      ansCn: '',
      stt: '00',
    })
    const fileList = ref([]);

    const isCateToggle = ref(false);
    const pageEditor = ref(null);
    const {showMessage} = useAlert();
    const inqTyCdDcds = computed(() =>
        store.state.code.codes.filter(code => code.cd.substring(4) !== '000' && code.cd.indexOf('2091') === 0));
    let $_instance = null;

    const uploadFiles = ref(null);
    const files = ref({files: [], removed: [], binaries: []});
    // const saveFiles = ref({ files: [], removed: [], binaries: [] });

    const addFile = () => {
      if(files.value.files.length === 5){
        showMessage('최대 5개 까지 등록가능합니다.');
        return false;
      }
      for(let i=0; i < uploadFiles.value.files.length; i++){
        files.value.files.push(uploadFiles.value.files[i]);
        fileList.value.push(uploadFiles.value.files[i]);
      }
    }

    onMounted(() => {
      uploadFiles.value = null;
      setPageEditor();
      if(inqItem.value.comInqSn > 0) {
        getQa();
      }
      setTimeout(() => {
        selectClass(inqTyCdDcds.value[0].cd, 0)
      },500)
    })

    onBeforeMount(() => {
      store.commit(`hrdcloud/${MUT_TOGGLE_CONCENTRATION_MODE}`, { 'mode' : 'fill'});
      if ( $_instance ) {
        $_instance.destroy();
        $_instance = null;
      }
    });

    onBeforeUnmount(() => {
      store.commit(`hrdcloud/${MUT_TOGGLE_CONCENTRATION_MODE}`, { 'mode' : ''});
    });

    const changeInqCd = (item) => {
      inqItem.value.inqTyNm = item.cdNm
      inqItem.value.inqTyCdDcd = item.cd;
      isCateToggle.value = false;
    }

    // 에디터 세팅
    const setPageEditor = () => {
      ClassicEditor.create( pageEditor.value, editorConfig )
          .then(editor => {
            editor.isReadOnly = false;
            $_instance = editor;
          })
          .catch( error => {
            console.error( error );
          });
    };

    const setInqCn = () => {
      if($_instance){
        $_instance.setData(inqItem.value.inqCn)
      }
    };

    const getQa = () => {
      store.dispatch(`help/${ACT_GET_HELP_QNA}`, inqItem.value.comInqSn
      ).then(res => {
        if (lengthCheck(res)) {
          let item = getItem(res);
          inqItem.value.comInqSn = item.comInqSn
          inqItem.value.inqTyCdDcd = item.inqTyCdDcd
          inqItem.value.inqTyNm = item.inqTyNm
          inqItem.value.inqTitle = item.inqTitle
          inqItem.value.inqCn = item.inqCn
          inqItem.value.readYn = item.readYn
          inqItem.value.ansYn = item.ansYn
          inqItem.value.ansCn = item.ansCn
          setInqCn();
          getHelpQaFileAtchList();
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const getHelpQaFileAtchList = () => {
      store.dispatch(`help/${ACT_GET_HELP_FILE_ATCH_LIST}`, {
        comInqSn: inqItem.value.comInqSn
      }).then(res => {
        if (lengthCheck(res)) {
          let items = getItems(res);
          items.forEach(item => {
            // files.value.files.push({name: item.fileNm, size: item.fileSz})
            fileList.value.push({name: item.fileNm, size: item.fileSz});
          })
        } else {
          files.value.files = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const clickSave = () => {
      inqItem.value.inqCn = $_instance.getData();

      if(inqItem.value.inqTitle === ''){
        showMessage('제목을 입력하세요');
        return false;
      }
      if(inqItem.value.inqCn === ''){
        showMessage('내용을 입력하세요');
        return false;
      }
      // 모바일에 카테고리 선택이 없음
      if(inqItem.value.inqTyCdDcd === ''){
        showMessage('카테고리를 선택하세요');
        return false;
      }

      if(route.query.comInqSn > 0){ // 수정
        store.dispatch(`help/${ACT_UPDATE_HELP_QNA}`, {
          comInqSn: inqItem.value.comInqSn,
          params: {
            ...inqItem.value
          }
        }).then(async (res) => {
          if (isSuccessFailAlert(res)) {
            if (files.value.files.length > 0) {
              let fileRes = await insertFileAtch({comInqSn: inqItem.value.comInqSn}, files.value.files);
              if (isUploadSuccess(fileRes)) {
                showMessage("QnA가 수정되었습니다.", goBack);
              } else {
                showMessage(serverUploadError);
              }
            } else {
              showMessage("QnA가 수정되었습니다", goBack);
            }
          }
        }).catch((err) => {
          console.error(err);
        })
      }else{ // 저장
        store.dispatch(`common/${ACT_INSERT_QA}`, inqItem.value)
            .then(async (res) => {
              if (isSuccess(res)) {
                if(files.value.files.length > 0){
                  let fileRes = await insertFileAtch({comInqSn: res.comInqSn}, files.value.files);
                  if (isUploadSuccess(fileRes)) {
                    showMessage("QnA가 등록되었습니다", goBack);
                  } else {
                    // 첨부파일 저장 실패 시 게시글 미등록 처리 (삭제)
                    await store.dispatch(`help/${ACT_DELETE_HELP_QNA}`, res.comInqSn);
                    showMessage(serverUploadError);
                  }
                }else{
                  showMessage("QnA가 등록되었습니다", goBack);
                }
              }
            }).catch((err) => {
              showMessage("QnA 등록 실패<br> 관리자에게 문의해주세요");
              console.error(err);
            });
      }
    }

    const removeFile = (item, idx) => {
      files.value.files.splice(idx, 1);
    }

    const selectClass = (dcd, idx) => {
      inqItem.value.inqTyCdDcd  = dcd;
      let cate = inqTyCdDcds.value.filter(x => x.cd === dcd);
      inqItem.value.inqTyNm = cate[0].cdNm;
      document.querySelectorAll('.snb-item').forEach(x => x.classList.remove('is-active'));
      document.querySelectorAll('.snb-item')[idx].classList.add('is-active');
    };

    return{
      inqItem,
      inqTyCdDcds,
      isCateToggle,
      pageEditor,
      uploadFiles,
      files,
      fileList,
      addFile,
      changeInqCd,
      setInqCn,
      clickSave,
      goBack,
      convertToStorageBytes,
      removeFile,
      selectClass,
    }
  }
};
</script>